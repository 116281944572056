<template>
  <!-- Component name -->
  <div class="wrapper-eventName">
    <ui-section-header
      additionalClass="has-margin-bottom"
      :showEdit="true"
      :editFunction="openEditName"
    >
      <template v-slot:title>{{ eventData.Name }}</template>
    </ui-section-header>

    <message
      v-if="eventData.Name === ''"
      type="is-danger"
      :message="$t('Components.Events.EventName.Error_EventName')"
    ></message>
    <!-- <div>
      {{ eventData.Name }}
    </div> -->
    <!-- <div class="text">
      <input
        type="text"
        v-model="eventName"
        class="input"
        disabled
      />
    </div> -->
    <modal-edit-event-name
      v-if="showEditEventName"
      :showModal="showEditEventName"
      :onClickCancel="hideModal"
    ></modal-edit-event-name>
  </div>
</template>

<script>
import Message from '@/components/UI/Message'
import { mapState } from 'vuex'

const ModalEditEventName = () =>
  import('@/components/Events/ModalEditEventName')

export default {
  components: {
    message: Message,
    'modal-edit-event-name': ModalEditEventName,
  },

  data() {
    return {
      showEditEventName: false,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),
  },

  methods: {
    openEditName() {
      this.showEditEventName = true
    },
    hideModal() {
      this.showEditEventName = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
