<template>
  <div class="input-url">
    <modal-edit-event-url
      v-if="showEditEventUrl"
      :showModal="showEditEventUrl"
      :onClickCancel="hideModal"
    ></modal-edit-event-url>
    <ui-section-header
      additionalClass="has-margin-bottom"
      :showEdit="true"
      :editFunction="openEditUrl"
    >
      <template v-slot:title>{{
        $t('Components.Events.EventUrl.Title_EventUrl')
      }}</template>
    </ui-section-header>
    <message
      v-if="this.eventData.Url === ''"
      :message="$t('Components.Events.EventUrl.Error_EventUrl')"
    />
    <div v-if="this.eventData.Url !== ''">
    <span v-if="this.eventData.IsTicketLink">{{$t('Components.Events.EventUrl.Label_TicketSale')}}</span>
    <span v-if="!this.eventData.IsTicketLink">{{$t('Components.Events.EventUrl.Label_MoreInfo')}}</span>
    <a @click="goToUrl" class="has-margin-bottom">{{ this.eventData.Url }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/UI/Message'

const ModalEditEventUrl = () => import('@/components/Events/ModalEditEventUrl')

export default {
  components: {
    message: Message,
    'modal-edit-event-url': ModalEditEventUrl
  },

  data() {
    return {
      showEditEventUrl: false
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData'])
  },

  methods: {
    goToUrl() {
      window.open(this.eventData.Url)
    },
    openEditUrl() {
      this.showEditEventUrl = true
    },
    hideModal() {
      this.showEditEventUrl = false
    }
  }
}
</script>
