<template>
  <div class="input-tags has-margin-bottom">
    <ModalEditEventTags
      v-if="showEditEventTags"
      :showModal="showEditEventTags"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      additionalClass="has-margin-bottom"
      :showEdit="true"
      :editFunction="openEditTags"
    >
      <template v-slot:title>{{
        $t('Components.Events.EventTags.Title_EventTags')
      }}</template>
    </ui-section-header>
    <message
      v-if="eventData.Tags.length === 0"
      :message="$t('Components.Events.EventTags.Error_EventTags')"
    ></message>
    <div v-if="eventData.Tags.length !== 0">
      <Tags :tags="eventData.Tags" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Message from '@/components/UI/Message'
import Tags from '@/components/UI/Tags'

const ModalEditEventTags = () =>
  import('@/components/Events/ModalEditEventTags')

export default {
  components: {
    message: Message,
    Tags,
    ModalEditEventTags,
  },

  data() {
    return {
      showEditEventTags: false,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),
  },

  methods: {
    openEditTags() {
      this.showEditEventTags = true
    },
    hideModal() {
      this.showEditEventTags = false
    },
  },
}
</script>

<style lang="scss" scoped>
.input-tags {
  .title-wrapper {
    display: flex;
    position: relative;
    a {
      position: absolute;
      right: 0;
    }
  }
}
</style>
