<template>
  <div>
    <ui-section-header
      additionalClass="has-margin-bottom"
      :showEdit="false"
    >
      <template v-slot:title>{{ $t(`Components.Events.EventDescription.Title_Location`)}}</template>
    </ui-section-header>

    <div class="image has-background-grey-light"></div>
    <div class="info">
      <span class="name">{{ eventData.LocationName }}</span>
      <span class="street">{{ eventData.Address }}</span>
      <span class="zipcode"
        >{{ eventData.PostalCode }} {{ eventData.City }}</span
      >
      <span class="country">{{ eventData.Country }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},

  props: {},

  data() {
    return {
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData'])
  },

  created() {},

  methods: {
  }
}
</script>

<style></style>
