import { render, staticRenderFns } from "./EventTags.vue?vue&type=template&id=23f42eb3&scoped=true"
import script from "./EventTags.vue?vue&type=script&lang=js"
export * from "./EventTags.vue?vue&type=script&lang=js"
import style0 from "./EventTags.vue?vue&type=style&index=0&id=23f42eb3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f42eb3",
  null
  
)

export default component.exports