<template>
  <div class="wrapper-image-date">
    <modal-edit-event-image
      v-if="showEditEventImage"
      :showModal="showEditEventImage"
      :onClickCancel="hideModalImage"
    ></modal-edit-event-image>
    <modal-edit-event-date-time
      v-if="showEditEventDateTime"
      :showModal="showEditEventDateTime"
      :onClickCancel="hideModalDateTime"
    ></modal-edit-event-date-time>

    <div class="columns is-multiline">
      <div class="column is-half">
        <ui-section-header additionalClass="has-margin-bottom">
          <template v-slot:title>{{ $t('Components.Events.EventImage.Title_EventImage') }}</template>
        </ui-section-header>

        <message
          v-if="eventImage === ''"
          type="is-danger"
          :message="$t('Components.Events.EventImage.Error_EventImage')"
        ></message>
        <button
          @click="openEditImage"
          class="button is-success has-margin-bottom"
        >
          <span class="icon">
            <font-awesome-icon
              :icon="['fas', 'image']"
              style="color:white"
            />
          </span>
          <span>{{ $t('Components.Events.EventImage.Icon_UploadImage') }}</span>
        </button>
      </div>
      <div class="column is-half">
        <ui-section-header
          additionalClass="has-margin-bottom"
          :showEdit="true"
          :editFunction="openEditDateTime"
        >
          <template v-slot:title>{{ $t('Components.Events.EventDescription.Label_Title_DateAndTime') }}</template>
        </ui-section-header>
        {{ printEventDate() }}<br />
        {{ printEventTime() }}
      </div>
      <div class="column is-full">
        <figure class="image is-3by1">
          <img
            src="@/assets/images/spacer3_1.gif"
            :style="`background-image: url('${eventImage}')`"
          />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Message from '@/components/UI/Message'

const ModalEditEventDateTime = () =>
  import('@/components/Events/ModalEditEventDateTime')
const ModalEditEventImage = () =>
  import('@/components/Events/ModalEditEventImage')

export default {
  name: 'event-image',
  components: {
    message: Message,
    'modal-edit-event-date-time': ModalEditEventDateTime,
    'modal-edit-event-image': ModalEditEventImage
  },
  computed: {
    ...mapState('eventStore', ['eventData', 'croppedEventImage']),
    ...mapGetters({
      eventDateTime: 'getEventDateTime'
    }),

    eventImage() {
      if (this.croppedEventImage instanceof Blob) {
        return URL.createObjectURL(this.croppedEventImage)
      } else if (this.eventData) {
        return this.$options.filters.getEventImage(this.eventData.Image)
      }
      return ''
    }
  },

  data() {
    return {
      showEditEventImage: false,
      showEditEventDateTime: false
    }
  },
  methods: {
    openEditDateTime() {
      this.showEditEventDateTime = true
    },
    openEditImage() {
      this.showEditEventImage = true
    },
    hideModalDateTime() {
      this.showEditEventDateTime = false
    },
    hideModalImage() {
      this.showEditEventImage = false
    },

    printEventDate() {
      let dateString = this.$options.filters.dateFormat(this.eventData.StartDate, this.$i18n.locale, 'longDateFormat')
      if (this.$options.filters.daysBetweenDates(new Date(this.eventData.StartDate), new Date(this.eventData.EndDate))) {
        // Multiple days event
        dateString += ' - ' + this.$options.filters.dateFormat(this.eventData.EndDate, this.$i18n.locale, 'longDateFormat')
      }
      return dateString
    },

    printEventTime() {
      let timeString = this.$options.filters.minutesToTime(this.eventData.StartMinutes) + ' - ' + this.$options.filters.minutesToTime(this.eventData.EndMinutes)
      if (this.eventData.StartMinutes === 0 && this.eventData.EndMinutes === 1440) {
        // All day event
        timeString = this.$t('Components.Events.EventList.Text_AllDay')
      }
      return timeString
    },
  }
}
</script>

<style lang="scss" scoped>
img {
  background-size: cover;
  background-position: center;
}
</style>
