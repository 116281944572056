<template>
  <div class="wrapper-program">
    <modal-edit-event-program
      v-if="showEditEventProgram"
      :showModal="showEditEventProgram"
      :onClickCancel="hideModal"
      :eventId="eventData.Id"
      :programItems="eventData.ProgramItems"
    ></modal-edit-event-program>

    <ui-section-header
      :showEdit="true"
      :editFunction="openEditProgram"
    >
      <template v-slot:title>{{ $t('Components.Events.EventProgram.Main_Title') }}</template>
    </ui-section-header>
    <message :message="$t('Components.Events.EventProgram.Message_EventProgram')"></message>
    <div v-if="eventData.ProgramItems.length !== 0">
      <div class="timeline">
          <div
            v-for="(item, index) in eventData.ProgramItems"
            :key="'programItem_' + index"
            class="timeline-item is-primary no-padding-bottom"
          >
            <div class="timeline-marker is-primary"></div>
            <div class="timeline-content">
              <p class="heading">{{ item.StartMinutes | minutesToTime }}</p>
              <p>{{ item.Description }}</p>
            </div>
          </div>
      </div>
    </div>
    <div
      v-else
      class="timeline"
    >
      <div
        v-for="i in 3"
        :key="'item_' +i"
        class="timeline-item is-primary no-padding-bottom"
      >
        <div class="timeline-marker is-primary"></div>
        <div class="timeline-content">
          <p class="heading">
            <TextPlaceholder
              :numberOfItems="1"
              :fixSize="60"
            />
          </p>
          <p>
            <TextPlaceholder :numberOfItems="5" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Message from '@/components/UI/Message'
import TextPlaceholder from '@/components/UI/TextPlaceholder'
const ModalEditEventProgram = () =>
  import('@/components/Events/ModalEditEventProgram')
export default {
  components: {
    message: Message,
    TextPlaceholder,
    'modal-edit-event-program': ModalEditEventProgram
  },
  data() {
    return {
      showEditEventProgram: false
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData'])
  },

  methods: {
    openEditProgram() {
      this.showEditEventProgram = true
    },
    hideModal() {
      this.showEditEventProgram = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
