<template>
  <div class="wrapper-description">
    <modal-edit-event-description
      v-if="showEditEventDescription"
      :showModal="showEditEventDescription"
      :onClickCancel="hideModal"
    ></modal-edit-event-description>
    <ui-section-header
      :showEdit="true"
      :editFunction="openEditDescription"
    >
      <template v-slot:title>{{ $t('Components.Events.EventDescription.Title_EventDescription') }}</template>
    </ui-section-header>
    <message
      v-if="eventData.Description === ''"
      :message="
        $t('Components.Events.EventDescription.Error_EventDescription')
      "
    ></message>
    <div class="content">
      <TextPlaceholder
        v-if="eventData.Description === ''"
        :numberOfItems="50"
      />
      <span
        v-else
        v-html="eventData.Description"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/UI/Message'
import TextPlaceholder from '@/components/UI/TextPlaceholder'

const ModalEditEventDescription = () =>
  import('@/components/Events/ModalEditEventDescription')

export default {
  components: {
    message: Message,
    TextPlaceholder,
    'modal-edit-event-description': ModalEditEventDescription
  },
  data() {
    return {
      showEditEventDescription: false
    }
  },
  methods: {
    openEditDescription() {
      this.showEditEventDescription = true
    },
    hideModal() {
      this.showEditEventDescription = false
    }
  },
  computed: {
    ...mapState('eventStore', ['eventData'])
  }
}
</script>

<style lang="scss" scoped>
</style>
