<template>
  <div class="wrapper-organizer">
    <manage-organizers
      v-if="showManageOrganizers"
      :showModal="showManageOrganizers"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      additionalClass="has-margin-bottom"
      :showEdit="true"
      :editFunction="setShowManageOrganizers"
    >
      <template v-slot:title>{{
        $t('Components.Events.EventOrganizer.Title_EventOrganizer')
      }}</template>
    </ui-section-header>
    <div v-if="!defaultOrganizer">
     <message
          :message="'Add one or more organizers for the event. Only the primary organizer will be displayed.'"
        />
    </div>   

    <div v-if="defaultOrganizer" class="organizer-data">
        <figure class="image is-64x64">
                     <img
                        src="@/assets/images/spacer1_1.gif"
                        class="is-rounded has-shadow"
                       :style="{backgroundImage: `url(${$options.filters.getEventImage(defaultOrganizer.Image, '160')})`}"
                     >
        </figure>

      <div class="info">
        <div class="name">{{ defaultOrganizer.Name }}</div>

        <div v-if="defaultOrganizer.Email" class="email">
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'envelope']" />
          </span>
          {{ defaultOrganizer.Email }}
        </div>

        <div v-if="defaultOrganizer.Phone" class="phone">
          <span class="icon">
            <font-awesome-icon :icon="['fas', 'mobile-alt']" />
          </span>
          {{ defaultOrganizer.Phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/UI/Message'

const ModalManageEventOrganizers = () =>
  import('@/components/Events/ModalManageEventOrganizers')

export default {
  components: {
    'manage-organizers': ModalManageEventOrganizers,
    message: Message
  },

  data() {
    return {
      showManageOrganizers: false,
      locationId: Number(this.$route.params.locationId),
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),

    defaultOrganizer() {
      let organizer = null

      if (this.eventData) {
        let organizerIndex = this.eventData.Organizers.findIndex(
          o => o.IsPrimary
        )
        if (organizerIndex > -1) {
          organizer = this.eventData.Organizers[organizerIndex]
        }
      }
      return organizer
    }
  },

  methods: {
    setShowManageOrganizers() {
      this.showManageOrganizers = true
    },

    hideModal() {
      this.showManageOrganizers = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.wrapper-organizer {
  .organizer-data {
    display: flex;
    align-items: center;
    .image {
      height: 85px;
      width: 85px;
      border-radius: 50px;
      margin-right: 10px;
    }
    .name {
      font-weight: bold;
    }
  }
}
</style>
